  
.rating{
    margin-top: 5rem;
    width: 100%;  
    float: left;
    background-color: white;
    margin-top: 3rem;
    text-align: center;
    /* border: 1px solid rgb(134, 129, 129);  */
    height: 300px;
    width: 250px;
    float: left;
    background-color: white;
    margin-left: 32px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}


.rating .pictures {
    /* border: 1px solid rgb(46, 127, 177); */
    border-radius: 80%;
    width: 130px;
}

.rating .profile_picture{
    display: inline-block; 
    position: relative; 
    width: 270px; 
    height: 200px; 
    overflow: hidden; 
    border-radius: 50%; 
}


.rating img { 
    /* width: auto;  */
    height: 100%; 
    margin-left: -20px; 
   
}
 .name3{
     margin-bottom: 15px;
     font-weight: bold;
     font-size: large;
 }

 .rewiew3{
    margin-top: 15px;
    font-style: italic;
    quotes: "„" "“";
}

.job3{
    margin-top: 10px;
    margin-bottom: 10px;
}


