.descriptions2 {
    width: 100%;
     margin-top: 5rem;
     width: 300px;
     background-color: white;
     margin: 5rem auto 0 auto;
     border-radius: 10px; 
     display: flex;
     flex-direction: column;
 }
 
 .descriptions2 .card2{
     text-align: center;
     /* border: 1px solid rgb(134, 129, 129); */
     height: 250px;
     width: 250px;
     float: left;
     background-color: white;
     margin-left: 32px;
     display: flex;
     justify-content: space-between;
     flex-direction: column;
 } 
 
   
   @keyframes animatedgradient {
     0% {
       background-position: 0% 50%;
     }
     50% {
       background-position: 100% 50%;
     }
     100% {
       background-position: 0% 50%;
     }
   }
 
 .descriptions2 .card:hover2{
     box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 0.253);
 }

 .descriptions2 .card_title2{
     padding-bottom: 10px;
 } 
 
 

 

 