.terms-text{
    max-width: 1000px;
    margin: auto;
}

.terms-text h2{
    text-align: center;
    margin-bottom: 10px;
}
    
.terms-text > h3{
    margin-top: 20px;
}