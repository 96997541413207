.search-container{
    max-width: 1240px;
    margin: 0px auto;
    margin-top: 20px;
    padding: 0 20px;
}
.search-item{
    margin: 15px 0px;
    border-radius: 5px;
    border: 1px solid steelblue;
}
.search-header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    align-items: center;
}
.search-title{
    align-self: center;
}
.search-nr{
    border: 1px solid steelblue;
    border-radius: 100%;
    background-image: linear-gradient(45deg, #5651e5 0%, #709dff 100%);
    padding: 2px 10px;
    color: white;
    font-weight: 500;
    margin-right: 10px;
}
.search-form{
    display: flex;
    flex-direction: column;
}
.search-form input{
    padding: 10px 15px;
    max-width: 500px;
    border-radius: 5px;
    margin-bottom: 10px;
}
.input-combo{
    margin-bottom: 15px;
}

.hide{
    display: none;
}

.searched-notlogged{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:  flex-start;
}

@media (max-width: 600px) {
    .searched-notlogged {
        flex-direction: column;
    }

    .searched-notlogged .m15 {
        margin-left: 0;
    }
}

.m15{
    margin-left: 15px;
}
.continue-btn{
    width: 200px;
    align-self: center;
}
/* result */
.header2{
    margin-bottom: 20px;
    text-align: center;
}
.results{
    margin-top: 20px;
}
.result-container{
    display: flex;
    flex-direction: row;
    margin: 15px 0px;   
    padding: 10px;
    background-color: aliceblue;
}
.result-text-container{
    margin: 15px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
}
.result-element-top, .result-element-bottom{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.result-element-bottom svg{
    width: 2em;
    height: 2em;
    cursor: pointer;
}
.first-svg{
    width: 1.9em;
    height: 1.9em;
    margin-right: 10px;
}
.filter-btn{
    border: 1px solid #ccc!important;
    border-radius: 16px;
}