.help-button-wrapper {
    position: fixed;
    bottom: 1em;
    right: 1em;
    text-align: center;
    z-index: 500;
  }
  .help-button {
    height: 6em;
    width: 6em;
    font-size: 14px;
    border-radius: 50%;
    border: 0 none;
    background: #1559ec;
    color: #fff;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    transform: scale(1);
    transition: all 200ms ease;
  }
  .help-button:hover,
  .help-button:focus,
  .help-button:active {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    outline: 0;
  }
  .help-button span {
    display: block;
    font-size: 2em;
    transform: scale(1);
    transition: transform 100ms ease;
  }
  .help-button:hover span,
  .expanded .help-button span,
  .expanded .help-button span {
    transform: scale(1.25);
  }
  .expanded .help-button {
    transform: scale(0.5);
    color: rgba(255, 255, 255, 0.5);
    background: #ffaa00;
  }
  
  