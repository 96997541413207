/* .divider-text {
    position: relative;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;

}
.divider-text span {
    padding: 7px;
    font-size: 12px;
    position: relative;   
    z-index: 2;
}
.divider-text:after {
    content: "";
    position: absolute;
    width: 100%;
    border-bottom: 1px solid #ddd;
    top: 55%;
    left: 0;
    z-index: 1;
}
.btn-facebook {
    margin-top: 25px;
    background-color: #c4d4f7!important;
    color: #fff;
}
.btn-twitter {
    margin-top: 25px;
    background-color: #42AEEC!important;
    color: #fff;
} */


body{
    /* fallback for old browsers */
    /* background: #bdc3c7;   */
    /* Chrome 10-25, Safari 5.1-6 */
    /* background: -webkit-linear-gradient(to right, #5493d1, #bdc3c7);   */
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    /* background: linear-gradient(to right, #5493d1, #9dceef);  */
}
/* 
.form{
    background-color: white;
    border-radius: 5px;
    width: 550px;
    margin: 20px auto;
    padding: 0px;

} */

/* .main-container {
    width: 85%;
    margin: 0 auto;
  }
  
  .main-container__header, .main-container__subheader {
    text-align: center;
  }
   */
  .form {
    max-width: 700px;
    margin: 0 auto;
    padding: 25px 15px;
  }
  
  .form-group {
    text-align: center
  }
  
  .form-group__element {
    margin: 10px auto;
  }
  
  .form-group__label {
    display: block;
    width: 300px;
    margin: 0 auto;
    text-align: left;
  }
  
  .form-group__input {
    width: 300px;
    padding: 10px;
    margin: 10px;
    border-radius: 3px;
    border: 1px solid #003487;
  }
  
  .buttons {
    display: flex;
    justify-content: center;
  }
  
  .buttons__button {
    width: 180px;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid #003487;
    margin: 5px 12px;
  }
  
  .buttons__button:hover {
    cursor: pointer;
    box-shadow: 3px 2px 3px rgba(175, 175, 175, 0.4);
  }
  
  .buttons__button--next {
    background-color: #0f6bff;
    color: #fff
  }
  
  .buttons__button--back {
    background-color: #fff;
    color: #003487
  }
  
  .select {
    text-align: center;
  }
  
  .select__item {
    width: 500px;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid #003487;
  }
  
  .select__item:hover {
    cursor: pointer
  }
  
  .table {
    width: 500px;
    margin: 15px auto;
  }
  
  .summary {
    width: 80%;
    margin: 20px auto;
    /* border: 1px solid #2b7cff; */
    padding: 10px;
    border-radius: 3px;
  }
  
  .summary__heading {
    margin-top: 5px;
    text-align: center;
  }
  
  .summary__item-title {
    font-weight: bold;
  }
  
  .stepper {
    padding: 3vh 0 !important;
  }
  
  .stepper__step {
    border: 1px solid #2b7cff !important;
    cursor: default !important;
  }
  
  .stepper__step + div > span {
    color: #0f6bff !important;
  }
  
  .RFS-ConnectorContainer {
    left: calc((-50% + 2em) - 8px) !important;
    right: calc((50% + 2em) - 8px) !important;
  }
  
  .error {
    margin-top: 0;
    color: red;
    font-size: 90%;
  }

  body{
    margin: 0;
    padding: 0;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttons__button--next:disabled {
  background-color: gray;
}

/* 
.form-body{
    text-align: left;
    padding: 20px 10px;
}

.form-body > *{
    padding: 5px;
}

.form__label{
    width: 40%;
}

.form_input{
    width: 60%;
}

.Footer{
    text-align: center;
}

.maincontainer {
    display: flex;
    justify-content: center;
}
.MuiTabs-flexContainer{
    justify-content: center !important;
}
.MuiTabs-fixed>span {
    background-color: #5651e5 !important;
} */
