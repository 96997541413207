.card_inner {
    min-width: 460px;
    padding: 15px;
    box-sizing: border-box;
}
.card_content {
    width: 100%;
    display: flex;
}

@media (max-width: 600px) {
    .card_content {
        flex-direction: column;
    }
}

.card_content .card_logo {
    width: 20%;
    min-width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card_content .card_logo img { width: 90%; }
.card_content .card_detail {
    width: 80%;
}

.card_content .card_detail .card_addr {
    text-align: right;
    font-style: italic;
}
.card_content .card_detail .card_contact {
    
}

.card_content .card_detail .card_contact {
    margin: 15px 0;
    display: flex;
    justify-content: right;
}
.card_content .card_detail .card_contact table td { padding: 2px 10px; }
.card_content .card_detail .card_contact a {
    color: blue;
}
.card_content .card_detail .card_contact table td.sm_icons { 
    padding-top: 10px; 
    text-align: right;
}

.card_content .card_detail .card_openhours {
    
}

@media (max-width: 500px) {
    .card_inner {
        min-width: 300px;
    }
}