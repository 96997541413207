.profile-img{
    border-radius: 15px;
    width: 150px;
}

.profile-img-gallery{
    width: 250px;
    /* display: flex; */

}
section {
    padding: 2rem 0;
  }
  
  .gallery_label {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dotted black;
    border-radius: 20px;
    width: 10rem;
    height: 10rem;
    cursor: pointer;
    font-size: large;
  }
  
  .gallery_label span {
    font-weight: lighter;
    font-size: small;
    padding-top: 0.5rem;
  }
  
  .inputs {
    display: none;
  }
  
  img.logo { max-width: 80px;}
  
  img {
    padding: 0;
    margin: 0;
  }
  
  .img_gallery {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
  }
  
  .img_gallery {
    margin: 1rem 0.5rem;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  }
  
  .img_gallery button {
    margin-top: 20px;
    /* position: absolute; */
    bottom: 0;
    right: 0;
    cursor: pointer;
    border: none;
    color: white;
    background-color: lightcoral;
  }
  
  .image button:hover {
    background-color: red;
  }
  
  .image p {
    padding: 0 0.5rem;
    margin: 0;
  }
  
  .upload-btn {
    cursor: pointer;
    display: block;
    margin: 0 auto;
    border: none;
    border-radius: 20px;
    width: 10rem;
    height: 3rem;
    color: white;
    background-color: #709dff;
  }
  
  /* label:hover,
  .upload-btn:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  } */
  
  .error {
    text-align: center;
  }
  
  .error span {
    color: red;
  }

.main-container{
    max-width: 1240px;
    margin: 0px auto;
}
.p-container{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    /* position: relative; */
}
.relat-btn{
    position: relative;
    display: flex;
    flex-direction: column;
}
.p-element{
    width: 100%;
    display: flex;
    flex-direction: column;
    /* border: 2px solid #00A4BD; */
    padding: 22px;
    margin: 0px 5px;
    border-radius: 12px;
    position: relative;
}
/* .p-element .MuiTextField-root{
    width: 250px; 
} */
.p-sub-container{
    display: flex;
    flex-direction: column;
}
.p-element-left{
    display: flex;
    flex-direction: column;
    position: relative;
}
.profile-input{
    margin: 10px 0px;
}
.relative-btn{
    position: absolute;
    top:5px;
    right:0px;
}
.hide{
    display: none;
}
.edit-btn{
    position: absolute !important;
    top: 22px;
    right: 22px;
}
.add-cat{
    display: flex;
    flex-direction: column;
}


.locItem {
  display: flex;
}
.locItem .locItemDetail {
  width: 70%;
}
.locItem .locItemActions {
  width: 30%;
  text-align: right;
}
.locItem .locItemActions > * { margin-right: 1em; }
.locMemo { text-align: center; }
@media (max-width: 600px) {

  .locItem { flex-direction: column; }

  .locItem .locItemDetail,
  .locItem .locItemActions {
    width: 100%;
  }
}

.errorMessage {
  border: 2px solid red;
  padding: 15px 20px;
  box-sizing: border-box;
}


.gImage {
  position: relative;
}
.gImage img {
  width: 100%;
  position: relative;
  z-index: 0;
}
.gImage a.removeBtn {
  width: 28px;
  height: 28px;
  display: inline-block;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #ccc;
  box-shadow: 0 0 3px 3px #666;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  text-align: center;
}