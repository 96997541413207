.footer-container {
    /* background-color: #709dff; */
    background-color: #fff;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.9;
}

.logofooter {
    width: 100px;
    height: 100px;
    overflow: hidden;
    color: #333;
}

/* Nem fontos */
/* .footer-container:hover{
    background-color: #2f2c83;
    background-color: #5651e5;
} */

.footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
}

.footer-subscription > p {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 24px;
    color: rgb(1, 1, 1);
}

.footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
}

/* .footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid rgb(0, 0, 0)
} */

.footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
    font-size: large;
}

.footer-link-wrapper {
    display: flex;
}

/* .footer-btn {
    color: black;
    width: 100px;
    height: 40px;
    background-color: var(--primary);
    border: 1px solid black;
    transition: all 0.3s ease-out;
    font-size: 20px;
    text-decoration: none;
} */

/* .footer-btn-hover {
    color: rgb(241, 245, 22);
    width: 100px;
    height: 40px;
    background-color: var(--primary);
    background-color: transparent;
    border: 1px solid rgb(201, 241, 22);
    transition: all 0.3s ease-out;
    font-size: 20px;
    text-decoration: none;
} */

.footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 50px;
    text-align: left;
    /* width: 160px; */
    box-sizing: border-box;
}

.footer-link-items > h3 {
    margin-bottom: 16px;
    /* color: black; */
}

.footer-link-items a {
    text-decoration: none;
    margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
    color: #03022b;
    transition: 0.3s ease-out;
}

.footer-input::placeholder {
    color: #03022b;
}

.social-icon-link {
    font-size: 24px;
}

.social-media {
    max-width: 1000px;
    width: 100%;
    margin-top: -4rem;
}

.social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
}

.social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
}

.social-logo {
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.website-rights {
    margin-bottom: 16px;
}


@media screen and (max-width: 820px) {
    .footer-links {
        padding-top: 2rem;
        size: 200px;
        font-size: larger;
    }
    
    .btn {
        width: 100%;
    }

    .footer-link-wrapper {
        /* flex-direction: column; */
        margin: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        max-width: 1240px;
        flex-wrap: wrap;
        flex-direction: row;
    }

    .social-media-wrap {
        display: flex;
        flex-direction: column;
    }
}