
.form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0 90px 0;
    line-height: 2.0;
    font-size:larger;
     /* display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: white;
    padding: 10px;
    margin: 10px 0px;
    padding: 10px 60px;
    border-radius: 10px; */
}

.login-wrapper {
    margin-top: 20px;
    /* display: flex;
    flex-direction: column; */
    
}


.input{
    font-size: large;
}

.login{
    margin-top: 20px;
    text-align: center;
}

.registration{
    margin-top: 40px;
    text-align: center;
}
/* 
.googlelogin{
    margin-top: 5px;
} */