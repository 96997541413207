.loginTitle {
    display: flex;
    justify-content: center;
    padding: 30px 0 20px 0;
}

.logAll {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 30px 0 90px 0;
    line-height: 2.0;
    font-size:larger;
    column-gap: 100px;


}

.buttons {
    display: flex;
    flex-direction: column;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 30px 0 90px 0; */
    line-height: 3.0;
    font-size:larger;
    align-content: space-between;
}

.buttons .butt1 {
    display: flex;
    flex-direction: row;
}

.buttons .butt2 {
    display: flex;
    flex-direction: row;
}


@media screen and (max-width:500px) {

    .logAll {
        margin: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        max-width: 600px;
        flex-wrap: wrap;
        flex-direction: row;
    }
}