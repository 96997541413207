.help {
    width: 100%;
    height: 250px;
}

.help_page_title {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}



.help_page_alig {
    margin: 50px;
}