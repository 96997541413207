.request-container{
    display: flex;
    flex-direction: row;
    margin: 15px 0px;
    padding: 10px;
    background-color: aliceblue;
    justify-content: space-between;
}
.request-left{
    display: flex;
    flex-direction: column;
}

.acc-request-container{
    display: flex;
    flex-direction: row;
    margin: 15px 0px;
    padding: 10px;
    background-color: #a7d49d;
    justify-content: space-between;
}