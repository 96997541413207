*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul{
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #333;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  padding-top: 90px;
}

.container {
  max-width: 1240px;
  /*minden kozepen*/
  margin: auto;
}

/*gombok osztaly*/
.btn {
  color: #fff;
  padding: 12px 24px;
  background-image: linear-gradient(45deg, #5651e5 0%, #709dff 100%);
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  transition: .4s;
  cursor: pointer;
}

.btn:active{
  transform: translate(4px);
  box-shadow: 0px 0px 1px rgba(0, 0, 0, .2);
  border-bottom: none;
}
/* Ertekeles h2 app js */
.title_rating{
  margin-top: 5rem;
  text-align: center;
  font-size: 2em;
}

.title_categories{
  margin-top: 5rem;
  text-align: center;
  font-size: 2em;
}

.container{
  display: block;
}
.desc-wrapper{
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* csak azért raktam,  mert a navbar is ilyen széles, ki lehet venni*/
  max-width: 1240px;
  flex-wrap: wrap;
}
.carousel-wrapper {
  /* max-width: 1240px; */
  /* padding: 5rem; */
  margin: auto;
}
@media screen and (max-width: 900px) {
  .carousel-wrapper {
    padding: 5rem 0;
  }
}

.parallax-image {
  height: 50vh;
  background-image: url('./asset/gps1.jpg');
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  color:rgba(0, 0, 0, .2)
}

.p0 {
  padding: 0 !important;
}

.under-cosntruction {
  max-width: 1200px;
  padding: 10px;
  margin: 0 auto;
}

.under-cosntruction h1 {
  padding-bottom: 10px;
}

.under-cosntruction img {
  max-width: 100%;

  max-height: 60vh;
}
  
