.hero {
    background-color: rgba(0, 0, 0, .5);
    color: #fff;
    height: 80vh;
    width: 100%;
    position: relative;
    margin-bottom: 4rem;
}

.hero img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    object-fit: cover;
}

.hero .heropictures:before {
    content: '';
    background: no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.hero .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    max-width: 500px;
    margin: auto;
    padding: 1rem;
}
/* 
.hero .heropictures {
    background-color: rgba(0, 0, 0, .5);
    color: #fff;
    height: 80vh;
    width: 100%;
    position: relative;
    margin-bottom: 4rem;
}

.hero .heropictures::before {
    content: '';
    background: no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
} */

.hero h1{
    font-size: 3.5rem;
    /* text-transform: capitalize; */
}

.hero .text{
    margin: 1rem;
    font-size: 1rem;
}


.btn2{
    color: #fff;
    padding: 12px 24px;
    background-image: linear-gradient(45deg, #5651e5 0%, #709dff 100%);
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    transition: .4s;
    cursor: pointer;
    width: 190px;
    align-self: center;
}


.btn:active{
    transform: translate(4px);
    box-shadow: 0px 0px 1px rgba(0, 0, 0, .2);
    border-bottom: none;
}

@media screen and (max-width: 940px){
    .hero{
        height: 70vh;
        
    }

    .hero h1{
        font-size: 2.5rem;
    }
}


