.has-error {
  border-color: red;
  border: 2px solid red;
  padding: 10px;
  box-shadow: 6px 4px 8px;
}
.add-request-container{
    max-width: 1240px;
    margin: 0px auto;
    margin-top: 20px;
}
.form-group-add-request{
    display: flex;
    flex-direction: column;
}
.form-control-textarea{
  resize: none;
}