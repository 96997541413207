.logo{
    width: 65px;
    overflow: hidden;

    @media (max-width: 550px) {
        width: 35px;
    }
}

.logoName{
    display: flex;
    align-items: center;
}

.navbar{
    position: fixed;
    z-index: 1200;
    width: 100%;
    height: 90px;
    top: 0;
    left: 0;
    background-color: #fff;
    border-bottom: 1px solid #eee;
    /* z-index: 100;
    overflow-x: hidden;
    position: sticky; */
}

.navbar .container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:1rem;
}

.navbar span {
    color:rgb(92, 87, 231);
}

.navbar .nav-menu {
    display: flex;
    transition: .5s ease-in-out right, 0.5 visibility;

}

.navbar .login-desktop {
    @media (max-width: 550px) {
        display: none;
    }
}

.navbar .login-mobile {
    display: none;

    @media (max-width: 550px) {
        display: block;
    }
}

.navbar li{
    padding: 1rem;
}

.hambi {
    display: none;
}

.navbar .icon {
    font-size: 1.5rem;
}

.navbar .langicon {
    font-size: 2em;
}

/*a navigacios bar reszpoziv lesz, egymas alatt a home statistics stb*/
@media screen and (max-width:1156px) {
/*minden 940 px nel kisebb ilyenre fog valtozni*/
    .logo {
        max-width: 200px;
    }

    .hambi {
        display: block;
    }
    
    .navbar .nav-menu{
        position: absolute;
        display: hidden;
        flex-direction: column;
        align-items: center;
        top: 90px;
        right: -100%;
        height: 100%;
        width: 100%;
        background: rgba(255, 255, 255, .98);
        transition: 0.4s ease-in-out;
        z-index: 10;
    }

    .nav-menu.active{
        z-index: 1200;
        display: flex;
        position: fixed;
        right: 0;
    }
}

/*telefonos eszkozokre*/
@media screen {
    
}

/*nagyobb betuk a lathatosag miatt*/
.navbar .nav-menu li a{
    font-size: 1.5rem;
    text-decoration: none;
}



