 .descriptions {
   /* width: 100%; */
    margin-top: 5rem;
    width: 300px;
    background-color: white;
    margin: 5rem auto 0 auto;
    border-radius: 10px; 
    display: flex;
    flex-direction: column;
}

.descriptions .card{
    text-align: center;
    /* border: 1px solid rgb(134, 129, 129); */
    height: 300px;
    width: 250px;
    float: left;
    background-color: white;
    margin-left: 32px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
} 

.card {
    --borderWidth: 4px;
    background: #1D1F20;
    position: relative;
    border-radius: var(--borderWidth);
  }
  .card:after {
    content: '';
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: linear-gradient(60deg, #c3ece5, #3ecbb6c4, #2dbfc1, #84c2e9, #5073b8, #1098ad, #07b39b, #6fba82);
    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }
  
  
  @keyframes animatedgradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

.descriptions .card:hover{
    box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 0.253);
}

.descriptions .card_body{
    margin-top: 16px;
    width: 250px;
    padding-right: 20px;
    margin-right: 20px;
    padding-left: 20px;
}

.descriptions .card_title{
    padding-bottom: 5px;
} 


.descriptions span {
    color: #5651e5;
}

.descriptions .card_icon{
    font-size: 3rem;
}

